<template>
  <div class="body add-business">
    <el-form
      :model="addForm"
      ref="addForm"
      :inline="true"
      label-width="110px"
      label-position="left"
      :disabled="disabled"
      class="demo-form-inline dialog-form dialog-form-inline"
    >
    <el-form-item label="站点类型：" prop="type">
        <el-select
          v-model="addForm.type"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in typeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="
          addForm.type == '0'
            ? '车站名称：'
            : addForm.type == '1'
            ? '站点名称：'
            : addForm.type == '2'
            ? '区域名称：'
            : '车站名称：'
        "
        prop="name"
      >
        <el-input v-model.trim="addForm.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="行政区划：" prop="parentAdCode">
        <el-cascader
          ref="cascader"
          v-model="addForm.parentAdCode"
          :options="cityList"
          :props="props"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="拼音码：" prop="pinyinCode">
        <el-input v-model.trim="addForm.pinyinCode"></el-input>
      </el-form-item>
      <el-form-item
        v-if="addForm.type == '2'"
        label="区域范围："
        prop="areaContent"
      >
          <el-input v-model.trim="addForm.areaContent"></el-input>
      </el-form-item>
      <el-form-item
        :label="
          addForm.type == '0'
            ? '车站地址：'
            : addForm.type == '1'
            ? '站点地址：'
            : addForm.type == '2'
            ? '默认上下车点：'
            : '车站地址：'
        "
        prop="detialAddress"
      >
          <el-input v-model.trim="addForm.detialAddress"></el-input>
      </el-form-item>
      <el-form-item
        v-if="addForm.type == '2'"
        label="默认点名称："
        prop="detialAddressName"
      >
        <el-input
          v-model.trim="addForm.detialAddressName"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input
          v-model.trim="addForm.remark"
          placeholder="请输入"
          type="textarea"
          rows="4"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

export default {
  props: {
    addForm: {
      type: Object,
      default: function (params) {
        return {}
      }
    },
    cityList: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      props: {
        value: "adcode",
        label: "name",
        children: "districts",
        checkStrictly: true,
      },
      typeList: [
        {
          dictValue: "车站",
          dictCode: "0"
        },
        {
          dictValue: "站点",
          dictCode: "1"
        },
        {
          dictValue: "区域",
          dictCode: "2"
        }
      ],
    }
  },
  methods: {
    
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.add-business {
  @include themify() {
    .modify {
      color: themed('n6');
      margin-left: 8px;
      &.active {
        color: themed('b4');
      }
    }
  }
}
</style>
