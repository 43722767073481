<template>
  <div class="point-map">
    <div class="searchBox">
      <i class="iconfont its_gy_sousuo"></i>
      <el-input
        id="tipinput"
        v-model="searchInput"
        type="text"
        clearable
        placeholder="输入搜索地址"
      ></el-input>
      <div class="searchContent">
        <div
          v-for="(item, index) in searchList"
          :key="index"
          class="content-address"
          @click="searchClick(item)"
        >
          <span class="address">{{ item.name }}</span>
          <span class="addressDetail">{{ item.address }}</span>
        </div>
      </div>
    </div>
    <div id="map" class="map"></div>
  </div>
</template>

<script>
export default {
  props: {
    addForm2Copy: {
      type: Object,
    },
  },
  data() {
    return {
      map: null,
      searchList: [],
      searchInput: "",
      pointForm: {
        name: "", //站点名称
        detialAddress: "",
        latitude: "",
        longitude: "",
        region: "",
      },
      marker: null,
      address: "四川",
    };
  },
  watch: {
    searchInput: {
      handler(val) {
        if (val) this.searchFun(val);
      },
    },
  },
  methods: {
    searchClick(item) {
      this.map.setCenter([item.location.lng, item.location.lat]);
      this.map.setZoom(16);
      this.pointForm.name = item.name;
      this.pointForm.detialAddress = item.address;
      this.pointForm.latitude = item.location.lat;
      this.pointForm.longitude = item.location.lng;
      this.searchList = [];
      this.searchInput = "";
    },
    searchFun(val) {
      let vm = this;
      AMap.plugin("AMap.PlaceSearch", function () {
        var autoOptions = {
          city: vm.pointForm.region,
          extensions: "all",
          citylimit: true, //是否强制在设置的城市内搜索
          autoFitView: true,
          children: 1,
        };
        var placeSearch = new AMap.PlaceSearch(autoOptions);
        placeSearch.search(val, function (status, result) {
          // 搜索成功时，result即是对应的匹配数据
          if (result.info == "OK") {
            vm.searchList = result.poiList.pois;
          }
        });
      });
    },
    // 实例化地图
    initMap() {
      this.map = null;
      this.map = new AMap.Map("map", {
        mapStyle: "amap://styles/3046c9ba72bbfebe766c8139f83b33c8",
        zoom: 14,
        resizeEnable: true,
        expandZoomRange: true,
        showIndoorMap: false,
      });
      if (this.addForm2Copy.areaContent) {
        var path = [];
        let arr = JSON.parse(this.addForm2Copy.areaContent);
        arr.forEach((item) => {
          path.push([item.lng, item.lat]);
        });
        var polygon = new AMap.Polygon({
          path: path,
          strokeColor: "#336FFE",
          strokeWeight: 4,
          strokeOpacity: 0.8,
          fillOpacity: 0.3,
          fillColor: "#336FFE",
          zIndex: 50,
        });
        this.map.add(polygon);
      }
      this.positionPicker();
    },
    // 拖拽选址方法
    positionPicker() {
      let vm = this;
      // 地图拖动的时候清空文本标记Marker Text
      this.map.on("drag", function () {
        vm.marker && vm.marker.hide();
      });
      this.map.on("dragend", function () {
        vm.marker && vm.marker.show();
      });
      // 地图拖拽
      AMapUI.loadUI(["misc/PositionPicker"], function (PositionPicker) {
        if (vm.pickerPosition) {
          vm.pickerPosition = null;
        }
        vm.pickerPosition = new PositionPicker({
          mode: "dragMap",
          map: vm.map,
          zIndex: 666,
          iconStyle: {
            //自定义外观
            url: require("@/assets/images/home/from_icon2.png"),
            ancher: [15, 30],
            size: [30, 39],
          },

          zIndex: 666,
        });
        vm.pickerPosition.on("success", function (positionResult) {
          if (positionResult.regeocode.pois.length > 0) {
            vm.pointForm.detialAddress = positionResult.address;
            vm.pointForm.name = positionResult.address;
            vm.pointForm.latitude = positionResult.position.lat;
            vm.pointForm.longitude = positionResult.position.lng;
          }
          if (!vm.marker) {
            vm.marker = new AMap.Text({
              text: positionResult.address,
              zIndex: 777,
              anchor: "center",
              offset: new AMap.Pixel(0, -60),
              style: {
                "box-shadow": "0px 2px 8px 0px rgba(199, 200, 201, 0.5)",
                padding: "14px",
              },
              position: [
                positionResult.position.lng,
                positionResult.position.lat,
              ],
            });
            vm.marker.setMap(vm.map);
          } else {
            vm.marker.setPosition([
              vm.pointForm.longitude,
              vm.pointForm.latitude,
            ]);
            vm.marker.setText(positionResult.address);
          }
        });
        vm.pickerPosition.start();
        vm.map.panBy(0, 1);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.point-map {
  width: 100%;
  height: 100%;
  position: relative;
  .map {
    width: 100%;
    height: 400px;
  }
  .searchBox {
    position: absolute;
    top: 10px;
    right: 30px;
    width: 230px !important;
    height: 28px !important;
    z-index: 2056;
    /deep/.el-input {
      input {
        padding-left: 30px;
      }
    }
    i {
      position: absolute;
      font-size: 20px;
      z-index: 2057;
      top: 4px;
      left: 6px;
    }
    .searchContent {
      max-height: 320px;
      overflow-x: hidden;
      overflow-y: auto;
      background: #ffffff;
    }
    .address {
      width: calc(100% - 8px);
      display: inline-block;
      cursor: pointer;
      padding: 4px 4px 0 4px;
      line-height: 24px;
    }
    .addressDetail {
      width: calc(100% - 8px);
      display: inline-block;
      border-bottom: 1px solid #f4f4f4;
      cursor: pointer;
      padding: 0 4px 4px 4px;
      line-height: 24px;
      font-size: 12px;
      color: #afafaf;
    }
  }
}
</style>