<template>
  <div class="add-site">
    <el-form
      :model="addForm"
      :rules="rule"
      ref="addForm"
      :inline="true"
      label-width="110px"
      label-position="left"
      class="demo-form dialog-form"
    >
      <el-form-item label="站点类型：" prop="type">
        <el-select
          v-model="addForm.type"
          clearable
          placeholder="请选择"
          :disabled="addForm.id ? true : false"
          @change="changeType"
        >
          <el-option
            v-for="item in typeList"
            :label="item.dictValue"
            :value="item.dictCode"
            :key="item.dictCode"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="
          addForm.type == '0'
            ? '车站名称：'
            : addForm.type == '1'
            ? '站点名称：'
            : addForm.type == '2'
            ? '区域名称：'
            : '车站名称：'
        "
        prop="name"
      >
        <el-input v-model.trim="addForm.name" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="行政区划：" prop="parentAdCode">
        <el-cascader
          ref="cascader"
          v-model="addForm.parentAdCode"
          :disabled="disabled"
          :options="cityList"
          :props="props"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="拼音码：" prop="pinyinCode">
        <el-input v-model.trim="addForm.pinyinCode"></el-input>
      </el-form-item>
      <el-form-item
        v-if="addForm.type == '2'"
        label="区域范围："
        prop="areaContent"
      >
        <div class="map-fix">
          <el-input v-model.trim="addForm.areaContentText" disabled></el-input>
          <el-button type="primary" class="map-btn" size="small" @click="mapAdd"
            >规划区域</el-button
          >
        </div>
      </el-form-item>
      <el-form-item
        :label="
          addForm.type == '0'
            ? '车站地址：'
            : addForm.type == '1'
            ? '站点地址：'
            : addForm.type == '2'
            ? '默认上下车点：'
            : '车站地址：'
        "
        prop="detialAddress"
      >
        <div class="map-fix">
          <el-input v-model.trim="addForm.detialAddress" disabled></el-input>
          <el-button
            type="primary"
            class="map-btn"
            size="small"
            :plain="
              addForm.areaContentText == '未规划' && addForm.id ? true : false
            "
            :disabled="
              addForm.areaContentText == '未规划' && addForm.id ? true : false
            "
            @click="openMapDialog"
            >地图获取</el-button
          >
        </div>
      </el-form-item>
      <el-form-item
        v-if="addForm.type == '2'"
        label="默认点名称："
        prop="detialAddressName"
      >
        <el-input
          v-model="addForm.detialAddressName"
          placeholder="请输入"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="备注：" prop="remark">
        <el-input
          v-model.trim="addForm.remark"
          placeholder="请输入"
          type="textarea"
          rows="4"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 地图获取地址弹窗 -->
    <el-dialog
      title="获取地址"
      :visible.sync="pointDialogVisible"
      v-if="pointDialogVisible"
      class="tree-operate-dialog"
      width="800px"
      append-to-body
      @close="pointDialogVisible = false"
    >
      <!-- 地图获取点地址 -->
      <point-map ref="mapPoint" :add-form2-copy="addForm"></point-map>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="pointDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="saveAddress"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 地图获取区域 -->
    <el-dialog
      :title="`规划区域【${addForm.name}】`"
      :visible.sync="regionDialogVisible"
      v-if="regionDialogVisible"
      class="tree-operate-dialog"
      width="800px"
      append-to-body
      @close="regionDialogVisible = false"
    >
      <!-- 地图绘制区域 -->
      <region-map
        ref="mapRegion"
        :add-form2-copy="addForm"
        :area-content="addForm.areaContent"
        :center="addForm.center"
        @areaContent="areaContentFUN"
        @changeAreaContent="changeAreaContent"
      />
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="regionDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="saveRegion"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { saveIntercityStation, updateIntercityStation } from "@/api/lib/api.js";
import pointMap from "./pointMap.vue";
import regionMap from "./regionMap";
export default {
  components: {
    pointMap,
    regionMap,
  },
  props: {
    cityList: {
      type: Array,
      default: () => [],
    },
    ruleForm: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: true,
    },
    channelNo: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      rule: {
        type: [{ required: true, message: "请选择必填项", trigger: "change" }],
        name: [{ required: true, message: "请输入", trigger: "blur" }],
        parentAdCode: [
          { required: true, message: "请选择必填项", trigger: "change" },
        ],
        pinyinCode: [{ required: true, message: "请输入", trigger: "blur" }],
        detialAddress: [
          // { required: true, message: "请获取", trigger: "blur" },
          // { required: true, message: "请选择", trigger: "change" }
          {
            validator: (rule, value, callback) => {
              if (this.addForm.detialAddress) {
                return callback();
              } else {
                callback(new Error("请获取"));
              }
            },
            trigger: "change",
          },
        ],
        detialAddressName: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        areaContent: [
          // { required: true, message: "请选择", trigger: "change" },
          {
            validator: (rule, value, callback) => {
              if (this.addForm.areaContent) {
                return callback();
              } else {
                callback(new Error("请规划"));
              }
            },
            trigger: "change",
          },
        ],
      },
      props: {
        value: "adcode",
        label: "name",
        children: "districts",
        checkStrictly: true,
      },
      typeList: [
        {
          dictValue: "车站",
          dictCode: "0",
        },
        {
          dictValue: "站点",
          dictCode: "1",
        },
        {
          dictValue: "区域",
          dictCode: "2",
        },
      ],
      pointDialogVisible: false, //地点弹窗
      addForm: {},
      regionDialogVisible: false, //区域弹窗
      arearForm: {},
      areaContentData: "", //暂存区域绘制结果
      area: 0,
    };
  },
  mounted() {
    this.addForm = { ...this.ruleForm };
    this.addForm.areaContentText =
      this.ruleForm && this.ruleForm.areaContent ? "已规划" : "未规划";
    this.$refs.addForm.clearValidate();
  },
  methods: {
    saveAddress() {
      const formValue = { ...this.$refs.mapPoint.pointForm };
      let inRing = true;
      if (this.addForm.areaContent) {
        var path = [];
        let arr = JSON.parse(this.addForm.areaContent);
        arr.forEach((item) => {
          path.push([item.lng, item.lat]);
        });
        inRing = AMap.GeometryUtil.isPointInRing(
          [formValue.longitude, formValue.latitude],
          path
        );
      }
      if (inRing) {
        this.addForm.detialAddress = formValue.detialAddress;
        // this.addForm.detialAddressName = formValue.name;
        this.$set(this.addForm, "detialAddressName", formValue.name); //普通赋值，复制后无法修改和删除
        this.addForm.detialAddressLocation = JSON.stringify([
          {
            lng: formValue.longitude,
            lat: formValue.latitude,
          },
        ]); //经纬度点
        this.pointDialogVisible = false;
      } else {
        this.$message.warning("请在规划区域内选择地点");
      }
    },
    areaContentFUN() {
      this.areaContentData = "";
      this.addForm.areaContent = "";
      this.addForm.areaContentText = "未规划";
    },
    changeAreaContent(obj) {
      this.areaContentData = JSON.stringify(obj.path);
      this.area = parseInt(obj.area);
    },
    saveRegion() {
      this.addForm.areaContent = this.areaContentData;
      if (this.areaContentData) {
        this.addForm.areaContentText = this.areaContentData
          ? "已规划"
          : "未规划";
        this.regionDialogVisible = false;
      } else {
        this.$message.warning("请先绘制区域");
      }
    },
    saveSite() {
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          let queryPath = null;
          if (this.addForm?.id) {
            queryPath = updateIntercityStation;
          } else {
            queryPath = saveIntercityStation;
          }
          this.addForm.area = this.area;
          this.addForm.channelNo = this.channelNo;
          queryPath(this.addForm).then((res) => {
            if (res.code === "SUCCESS") {
              this.$message.success("操作成功");
              this.$emit("closeDialog");
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    mapAdd() {
      this.regionDialogVisible = true;
    },
    openMapDialog() {
      this.pointDialogVisible = true;
      this.addForm.region = this.$refs.cascader.getCheckedNodes()[0].label;
      if (this.addForm?.id) {
        let latitude = JSON.parse(this.addForm.detialAddressLocation)[0]?.lat;
        let longitude = JSON.parse(this.addForm.detialAddressLocation)[0]?.lng;
        this.$nextTick(() => {
          this.$refs.mapPoint.pointForm = {
            name: this.addForm.detialAddressName, //站点名称
            detialAddress: this.addForm.detialAddress,
            latitude: latitude,
            longitude: longitude,
            region: this.addForm.region,
          };
          this.$refs.mapPoint.initMap();
          this.$refs.mapPoint.map.setCenter([longitude, latitude]);
        });
      } else {
        this.$nextTick(() => {
          this.$refs.mapPoint.pointForm.region = this.addForm.region;
          this.$refs.mapPoint.initMap();
          this.addForm.center &&
            this.$refs.mapPoint.map.setCenter(this.addForm.center); //定位到所选城市
        });
      }
    },
    changeType() {
      let title = "";
      if (this.addForm.type === "0") {
        title = this.addForm?.id ? "编辑车站" : "添加车站";
      } else if (this.addForm.type === "1") {
        title = this.addForm?.id ? "编辑站点" : "添加站点";
      } else if (this.addForm.type === "2") {
        title = this.addForm?.id ? "编辑区域" : "添加区域";
      }
      this.addForm.areaContent = ""; //初始化区域
      this.addForm.areaContentText = "未规划";
      this.$emit("changeTitle", title);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-site {
  .map-btn {
    position: absolute;
    right: -100px;
    top: 4px;
  }
  .map-fix {
    width: 100%;
    position: relative;
  }
}
</style>
