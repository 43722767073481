var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "body add-business" },
    [
      _c(
        "el-form",
        {
          ref: "addForm",
          staticClass: "demo-form-inline dialog-form dialog-form-inline",
          attrs: {
            model: _vm.addForm,
            inline: true,
            "label-width": "110px",
            "label-position": "left",
            disabled: _vm.disabled,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "站点类型：", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.addForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.addForm, "type", $$v)
                    },
                    expression: "addForm.type",
                  },
                },
                _vm._l(_vm.typeList, function (item) {
                  return _c("el-option", {
                    key: item.dictCode,
                    attrs: { label: item.dictValue, value: item.dictCode },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  _vm.addForm.type == "0"
                    ? "车站名称："
                    : _vm.addForm.type == "1"
                    ? "站点名称："
                    : _vm.addForm.type == "2"
                    ? "区域名称："
                    : "车站名称：",
                prop: "name",
              },
            },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入" },
                model: {
                  value: _vm.addForm.name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "行政区划：", prop: "parentAdCode" } },
            [
              _c("el-cascader", {
                ref: "cascader",
                attrs: { options: _vm.cityList, props: _vm.props },
                model: {
                  value: _vm.addForm.parentAdCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.addForm, "parentAdCode", $$v)
                  },
                  expression: "addForm.parentAdCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "拼音码：", prop: "pinyinCode" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.addForm.pinyinCode,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "pinyinCode",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.pinyinCode",
                },
              }),
            ],
            1
          ),
          _vm.addForm.type == "2"
            ? _c(
                "el-form-item",
                { attrs: { label: "区域范围：", prop: "areaContent" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.addForm.areaContent,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "areaContent",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.areaContent",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  _vm.addForm.type == "0"
                    ? "车站地址："
                    : _vm.addForm.type == "1"
                    ? "站点地址："
                    : _vm.addForm.type == "2"
                    ? "默认上下车点："
                    : "车站地址：",
                prop: "detialAddress",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.addForm.detialAddress,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "detialAddress",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.detialAddress",
                },
              }),
            ],
            1
          ),
          _vm.addForm.type == "2"
            ? _c(
                "el-form-item",
                { attrs: { label: "默认点名称：", prop: "detialAddressName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.addForm.detialAddressName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addForm,
                          "detialAddressName",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addForm.detialAddressName",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "备注：", prop: "remark" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入", type: "textarea", rows: "4" },
                model: {
                  value: _vm.addForm.remark,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.addForm,
                      "remark",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "addForm.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }