<template>
  <div class="business-management">
    <div class="tabs">
      <el-tabs v-model="channelNo" type="card" @tab-click="getCompanyList">
        <el-tab-pane
          v-for="(item, index) in channelList"
          :key="index"
          :label="item.channelName"
          :name="item.channelNo"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div class="btns">
      <el-button type="primary" size="small" @click="addCityFun"
        >添加行政区划</el-button
      >
    </div>
    <div class="tree-main">
      <div class="left">
        <el-tree
          :data="treeData"
          :props="defaultProps"
          ref="companyTree"
          node-key="id"
          default-expand-all
          :expand-on-click-node="false"
          :highlight-current="true"
          :default-expanded-keys="[0]"
          @node-click="nodeClick"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span class="treeName">{{ node.label }}</span>
            <span>
              <!-- 加号 v-if="data.level !== 'province' && data.level !== 'country' && data.level !== 'city'" -->
              <el-button
                v-if="data.level === 'district'"
                class="icon-menu-btn"
                type="text"
                size="mini"
                @click.stop="() => addSiteFun(node, data, 0)"
              >
                <i class="icon-menu el-icon-circle-plus-outline"></i>
              </el-button>
              <!-- 编辑 -->
              <el-button
                v-if="!data.level && data.intercityStation"
                class="icon-menu-btn"
                type="text"
                size="mini"
                @click.stop="() => addSiteFun(node, data, 1)"
              >
                <i class="icon-menu el-icon-edit-outline"></i>
              </el-button>
              <!-- 减号 -->
              <el-button
                v-if="!data.level && data.intercityStation"
                class="icon-menu-btn"
                type="text"
                size="mini"
                @click.stop="() => remove(node, data)"
              >
                <i class="icon-menu el-icon-remove-outline"></i>
              </el-button>
            </span>
          </span>
        </el-tree>
      </div>
      <div class="right">
        <business
          v-if="dialogVisible"
          :addForm="addSiteForm"
          :cityList="cityList"
          :disabled="true"
        />
      </div>
    </div>
    <!-- 添加行政区划 -->
    <el-dialog
      title="添加行政区划"
      :visible.sync="cityDialogVisible"
      v-if="cityDialogVisible"
      class="tree-operate-dialog"
      width="30%"
      :close-on-click-modal="false"
      @close="closeCiytAddDialog"
    >
      <el-form
        ref="cityForm"
        :model="cityForm"
        :rules="rules"
        label-width="110px"
        label-position="left"
        class="demo-form dialog-form"
      >
        <el-form-item label="行政区划：" prop="cityCodes">
          <el-cascader
            ref="cascader"
            v-model="cityForm.cityCodes"
            :disabled="disabled"
            :options="cityList"
            :props="props"
            :change-on-select="true"
            @change="handleCascaderCity"
          ></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeCiytAddDialog">取 消</el-button>
        <el-button type="primary" size="small" @click="saveCity"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 添加车站/站点/区域 -->
    <el-dialog
      :title="siteTitle"
      :visible.sync="siteDialogVisible"
      v-if="siteDialogVisible"
      class="tree-operate-dialog"
      width="520px"
      :close-on-click-modal="false"
      @close="siteDialogVisible = false"
    >
      <site-dialog
        ref="siteDialog"
        :cityList="cityList"
        :ruleForm="addSiteForm"
        :channelNo="channelNo"
        @changeTitle="changeTitle"
        @closeDialog="closeDialog"
      ></site-dialog>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="siteDialogVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="saveSite"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import siteDialog from "./component/siteAddDialog.vue";
import business from "./component/business.vue";
import {
  queryIntercityStationTree,
  saveIntercityAdministrativeDivisions,
  deleteIntercityStationById,
  getCityCodeAPI,
  getCurrentUserChannelInfoAPI,
} from "@/api/lib/api.js";

export default {
  name: "menuManagement",
  components: {
    siteDialog,
    business,
  },
  data() {
    return {
      treeData: [],
      defaultProps: {
        children: "districts",
        label: "name",
      },
      addForm: {},
      dialogVisible: false,
      title: "",
      // 添加行政区划
      cityList: [],
      cityDialogVisible: false,
      props: {
        value: "adcode",
        label: "name",
        children: "districts",
        checkStrictly: true,
      },
      cityForm: {
        cityCodes: "",
        nodeData: [],
        parentAdCode: [],
      },
      disabled: false,
      rules: {
        cityCodes: [{ required: true, message: "请选择", trigger: "change" }],
      },
      //站点/车站/区域新增
      siteTitle: "添加车站",
      addSiteForm: {},
      siteDialogVisible: false,
      center: null,
      channelList: [],
      channelNo: "",
    };
  },
  methods: {
    //获取渠道数据
    getQueryChannelList() {
      getCurrentUserChannelInfoAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.channelList = res.data;
          this.channelNo = res.data[0]?.channelNo;
          this.getCompanyList();
        }
      });
    },
    //打开行政区域新增弹窗
    addCityFun() {
      this.cityDialogVisible = true;
    },
    //获取城市代码
    getCityCode() {
      getCityCodeAPI().then((res) => {
        if (res.code === "SUCCESS") {
          this.cityList = this.getTreeData(res.data[0].districts);
        }
      });
    },
    getTreeData(data) {
      for (let index = 0; index < data.length; index++) {
        if (data[index].districts.length < 1) {
          data[index].districts = undefined;
        } else {
          this.getTreeData(data[index].districts);
        }
      }
      return data;
    },
    //选择城市
    handleCascaderCity(val) {
      const node = this.$refs.cascader.getCheckedNodes();
      this.cityForm.nodeData = node[0].pathNodes;
      this.cityForm.parentAdCode = val;
    },
    closeCiytAddDialog() {
      this.cityDialogVisible = false;
      this.cityForm = this.$options.data().cityForm;
      this.$refs.cityForm.resetFields();
    },
    //保存行政区划新增
    saveCity() {
      this.$refs.cityForm.validate((valid) => {
        if (valid) {
          const nodeSingleArr = [...this.cityForm.nodeData];
          let resultObj = {};
          nodeSingleArr.forEach((item) => {
            if (item.level === 1) {
              resultObj = {
                citycode: item.data.citycode,
                adcode: item.data.adcode,
                level: item.data.level,
                center: item.data.center,
                name: item.data.name,
                districts: [],
              };
            } else if (item.level === 2) {
              resultObj.districts.push({
                citycode: item.data.citycode,
                adcode: item.data.adcode,
                level: item.data.level,
                center: item.data.center,
                name: item.data.name,
                districts: [],
              });
            } else if (item.level === 3) {
              resultObj.districts[0].districts.push({
                citycode: item.data.citycode,
                adcode: item.data.adcode,
                level: item.data.level,
                center: item.data.center,
                name: item.data.name,
                districts: [],
              });
            } else if (item.level === 4) {
              resultObj.districts[0].districts[0].districts.push({
                citycode: item.data.citycode,
                adcode: item.data.adcode,
                level: item.data.level,
                center: item.data.center,
                name: item.data.name,
                districts: [],
              });
            }
          });
          resultObj.parentAdCode = this.cityForm.parentAdCode;
          resultObj.channelNo = this.channelNo;
          saveIntercityAdministrativeDivisions(resultObj).then((res) => {
            if (res.code === "SUCCESS") {
              this.$message.success("新增成功");
              this.closeCiytAddDialog();
              this.getCompanyList();
            }
          });
        }
      });
    },
    //打开添加车站/站点/区域弹窗
    addSiteFun(node, data, flag) {
      this.addSiteForm = {};
      if (flag === 0) {
        this.siteTitle = "添加车站";
        this.addSiteForm.type = "0";
        this.addSiteForm.name = data.name;
        this.addSiteForm.administrativeDivisionsId = data.id;
        this.addSiteForm.parentAdCode = [...data.parentAdCode]; //行政区划回显值
      } else if (flag === 1) {
        this.addSiteForm = { ...data.intercityStation };
        this.addSiteForm.type = data.intercityStation?.type + "";
        if (data.intercityStation?.type === 0) {
          this.siteTitle = "编辑车站";
        } else if (data.intercityStation?.type === 1) {
          this.siteTitle = "编辑站点";
        } else if (data.intercityStation?.type === 2) {
          this.siteTitle = "编辑区域";
        }
      }
      const obj = node.parent.data;
      this.addSiteForm.center = obj.center?.split(","); //地图区域展示中心点位置
      this.siteDialogVisible = true;
    },
    closeDialog() {
      this.siteDialogVisible = false;
      this.getCompanyList();
    },
    changeTitle(title) {
      this.siteTitle = title;
    },
    //
    saveSite() {
      this.$refs.siteDialog.saveSite();
    },
    // 删除节点操作
    remove(node, data) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        type: "warning",
        message: h("p", null, [h("span", null, "确认删除该节点？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          deleteIntercityStationById({ id: data.intercityStation?.id }).then(
            (res) => {
              if (res.code === "SUCCESS") {
                this.$message.success("操作成功");
                this.getCompanyList();
              } else {
                this.$message.error(res.msg);
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消删除",
          });
        });
    },
    // 点击节点
    nodeClick(data) {
      if (data.intercityStation) {
        this.addSiteForm = { ...data.intercityStation };
        this.addSiteForm.type = data.intercityStation?.type + "";
        this.addSiteForm.administrativeDivisionsId =
          data.intercityStation.parentId;
      } else {
        this.addSiteForm = { ...data };
      }
      this.dialogVisible = true;
    },
    // 保存添加和编辑
    saveTree() {
      this.$refs.addCompany.onSave();
    },
    // 获取企业树
    getCompanyList() {
      queryIntercityStationTree({ channelNo: this.channelNo }).then((res) => {
        if (res.code === "SUCCESS") {
          this.treeData = res.data;
        }
      });
    },
  },
  created() {
    this.getCityCode();
    this.getQueryChannelList();
  },
};
</script>

<style lang="scss" scoped>
.business-management {
  border-radius: 4px;
  padding: 16px 20px;
  height: calc(100% - 32px);
  .tree-main {
    box-sizing: border-box;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 16px;
    height: calc(100% - 105px);
  }
  @include themify() {
    .left {
      width: 35%;
      background-color: themed("n1");
      padding: 20px;
      overflow: auto;
      .treeName {
        margin-right: 15px;
      }
      .icon-menu-btn {
        margin-left: 0;
        .icon-menu {
          padding: 5px;
        }
      }
    }
    .right {
      width: 65%;
    }
  }
}

.business-management {
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
}
</style>
